import { Link } from 'react-router';
import { useEffect, useState } from 'react';
import Tooltip from '~/components/shared/Tooltip';
import type { User } from '~/models/user.server';
import { getRandomPrimaryColorVar } from '~/utils/colors';
import './users-avatars.css';
import { IconMail } from '@fastly/beacon-icons';
import type { Team } from '~/models/team.server';

export const UserAvatar = ({
  user,
  invited,
}: {
  user: User;
  invited?: boolean;
}) => {
  const [backgroundColor, updateBackgroundColor] = useState('');

  // We set the background color in state
  // So we do not get hydration errors with SSR
  useEffect(() => {
    updateBackgroundColor(getRandomPrimaryColorVar());
  }, [updateBackgroundColor]);

  return (
    <Tooltip text={user.login as string}>
      <Link
        aria-label={user.login as string}
        to={`/user/${user.login}`}
        className={invited ? 'user-avatars-invited' : undefined}
      >
        {user.avatarUrl ? (
          <img src={user.avatarUrl} alt="" />
        ) : (
          <span
            style={{
              backgroundColor: `var(--${backgroundColor})`,
            }}
            className="user-avatars-placeholder"
          >
            {user.login?.charAt(0).toUpperCase()}
          </span>
        )}
      </Link>
    </Tooltip>
  );
};

const UsersAvatars = ({
  allowListDomain,
  invitedUsers,
  users,
  loading,
}: {
  allowListDomain?: Team['whitelistedDomain'];
  invitedUsers?: User[];
  users?: User[];
  loading?: boolean;
}) => {
  return (
    <ul className="users-avatars">
      {loading &&
        !users &&
        [...Array(2)].map((_, index) => (
          <li aria-hidden key={index} className="loading" />
        ))}
      {users &&
        users.map((user) => (
          <li key={user.id}>
            <UserAvatar user={user} />
          </li>
        ))}
      {invitedUsers &&
        invitedUsers.map((user) => (
          <li key={user.id}>
            <UserAvatar invited user={user} />
          </li>
        ))}
      {allowListDomain && (
        <li>
          <Tooltip text={`Anyone with a @${allowListDomain} email can join`}>
            {' '}
            <div
              style={{
                backgroundColor: `var(--color-primary-2)`,
              }}
              className="user-avatars-placeholder"
            >
              <IconMail width="30px" />
            </div>
          </Tooltip>
        </li>
      )}
    </ul>
  );
};

export default UsersAvatars;
